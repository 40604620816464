import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from '@sainsburys-tech/bolt'
import { trackLhnCategorySelect } from '../../helpers/TaggingSerializer'
import * as Styled from './styles'

const Category = ({ itemName, id, categoryPath }) => {
  const onClick = event => {
    event.preventDefault()
    trackLhnCategorySelect(itemName, id)
    window.location.href = categoryPath
  }

  return (
    <ErrorBoundary>
      <Styled.CategoryItemWrapper as='li' data-el='category-item' data-test={`category-item-${id}`}>
        <Styled.CategoryLink data-category-id={id} onClick={event => onClick(event)} href={categoryPath}>
          {itemName}
        </Styled.CategoryLink>
      </Styled.CategoryItemWrapper>
    </ErrorBoundary>
  )
}

Category.propTypes = {
  id: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  categoryPath: PropTypes.string.isRequired
}

export default Category
